<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import { logicFactoryRank } from '@/logic/rank/factory'

const {
  labelList,
  active,
  data,
  getRankData,
} = logicFactoryRank()

await getRankData()
</script>

<template>
  <n-card
    title="厂商排行" embedded size="small"
    class="rounded-md"
    header-style="--n-title-font-weight: bold;"
  >
    <template #header>
      厂商排名<span class="text-red-4 text-3 font-400">（近两年）</span>
    </template>
    <template #header-extra>
      <BasicSwitchTab v-model:active="active" :key-list="labelList" />
    </template>
    <UtilLoadState :state="data[active].loadState" :reload-handler="getRankData">
      <template v-if="data[active].list.length">
        <NuxtLink
          v-for="(v, i) in data[active].list" :key="i"
          class="mb-sm text-3.5 flex items-center last:mb-0"
          :to="APP_ROUTER.complaintSearch({ carVendorId: v.carVendorId, carVendorName: v.vendorName })"
        >
          <span class="rank mr-sm" :class="`rank__${i}`">{{ i + 1 }}</span>
          <span class="line-clamp-1 text-3.5 font-bold hover:text-orange-6 flex-1">
            {{ v.vendorName }}
          </span>
          <span class="text-red-5 text-4.25 ml-sm font-mono">
            {{ active === 1 ? v.qt : `${Math.floor(v.replyRate * 100)}%` }}
          </span>
        </NuxtLink>
      </template>
      <BasicEmpty v-else />
    </UtilLoadState>
  </n-card>
</template>

<style lang='scss' scoped>
@import url(@/assets/styles/rank.scss);
</style>
